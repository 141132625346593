@font-face {
  font-family: "San Francisco";
  font-weight: 400;
  src: url("sanfranciscodisplay-regular-webfont.woff") format("woff");
}

html, body {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  font-size: 17px;
  line-height: 1.47059;
  font-weight: 400;
  letter-spacing: -0.022em;
  font-family: "San Francisco", "SF Pro Text", "SF Pro Icons", "AOS Icons", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  background-color: rgb(255, 255, 255);
  color: rgb(29, 29, 31);
  font-style: normal;
  overflow-x: hidden;
  position: relative
}

.content {
  height: 85%;
  padding: 25px;
  padding-top: 0px;
  margin: 0 140px;
}

/* Media query for phones (portrait and landscape) */
@media only screen and (max-width: 1240px) {
  .content {
    height: 85%;
    padding: 25px;
    padding-top: 0px;
    margin: 0 15px;
  }
}

/* Tablet media query */
@media only screen and (min-width: 768px) and (max-width: 1240px) {
  .content {
    height: 85%;
    padding: 25px;
    padding-top: 0px;
    margin: 0 120px;
  }
}

.content-header {
  font-size: 48px;
  line-height: 1.08349;
  font-weight: 600;
  letter-spacing: -.003em;
  color: #007AFF;
  font-family: "San Francisco", "SF Pro Display", "SF Pro Icons", "AOS Icons", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.content-underline {
  color: #CECED2;
}

.card {
  overflow: hidden;
  background-color: #fff;
  border-radius: 18px;
  width: 28.23529rem;
  height: 29.41176rem;
  white-space: normal;
  box-shadow: 2px 4px 12px rgba(0, 0, 0, .08);
  transition: all .3s cubic-bezier(0, 0, .5, 1);
}

.container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
  gap: 50px;
  height: 100%;
  width: 100%;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
  gap: 50px;
}

.gap-125 {
  gap: 125px;
}

.gap-175 {
  gap: 175px;
}

/* Media query for phones (portrait and landscape) */
@media only screen and (max-width: 1240px) {
  .container {
    display: block;
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    gap: 0px;
    height: 100%;
    width: 100%;
  }
  .profile-container {
    display: block;
  }
  .skill-container {
    display: block;
  }
  .card {
    width: initial;
  }
}

.box {
  height: 100%;
}

.align-children-vertically {
  display: flex;
  align-items: center;
}

/* Media query for phones (portrait and landscape) */
@media only screen and (max-width: 1240px) {
  section {
    padding-top: 45px;
  }

  .box {
    height: initial;
    /*
    padding-top: 25px;
    padding-bottom: 25px;
    */
  }
  
  .align-children-vertically {
    display: block;
    align-items: center;
  }
}

.align-children-horizontally {
  display: flex;
  justify-content: center;
}

.full-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Use "cover" to ensure the image covers the container */
}

/* Tablet media query */
@media only screen and (min-width: 768px) and (max-width: 1240px) {
  .full-image {
    object-position: top;
  }
}

.text-content-card-height {
  height: 29.41176rem;
}

.grid-container-education {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 1fr);
  gap: 10px;
}

.grid-item-education {
  height: 100%;
  width: 100%;
  white-space: pre-line;
}

.text-align-left {
  text-align: left;
}

.margin-top-25 {
  margin-top: 25px;
}

.margin-top-50 {
  margin-top: 50px;
}

.margin-top-75 {
  margin-top: 75px;
}

/* Media query for phones (portrait and landscape) */
@media only screen and (max-width: 1240px) {
  .mobile-margin-top {
    margin-top: 5px;
    padding-bottom: 0px;
  }
  .text-content-card-height {
    height: initial;
  }
}

.no-margin {
  margin-top: 0px !important;
  margin-block-start: 0px !important;
  margin-inline-end: 0px !important;
  margin-block-end: 0px !important;
  margin-inline-start: 0px !important;
}

.subheader {
  font-size: 24px;
  line-height: 1.42859;
  font-weight: 400;
  letter-spacing: -.016em;
  font-family: "San Francisco", "SF Pro Icons", "AOS Icons", "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
  font-weight: 600;
  display: block;
  color: #1d1d1f;
  text-align: left;
  white-space: nowrap;
}

.button-link {
  background: transparent;
  border: 0;
  color: #06c;
  cursor: pointer;
  text-decoration: none;
}

.description-box-date {
  text-align: right;
  font-size: 14px;
  margin: 0;
  padding: 0;
}

.description-box-header {
  font-size: 14px;
  margin: 0;
  padding: 0;
}

.description-box-title {
  font-size: 20px;
  margin: 0;
  padding: 0;
}

.description-box-sub-header {
  font-size: 16px;
  color: #6e6e73;
}

.description-box-text {
  font-size: 16px;
  margin-top: 8px;
}

.skill {
  top: -4px;
}