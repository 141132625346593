/* MobileNav.module.css */

.header {
    position: fixed;
    z-index: 99;
    top: 0;
    zoom: 75%;
    width: 100%;
    z-index: 12;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-evenly;
    height: 20vh;
}

.navSocial a {
    text-decoration: none;
    color: white;
    font-size: 21px;
}

.navSocial {
    width: 15vw;
}

.navSocial svg {
    display: block;
}

.navSocial ul {
    margin: 0;
    display: flex;
    flex-flow: row nowrap;
    list-style: none;
    float: right;
}

.navMenu {
    width: 33.3vw;
}

.navLinks {
    width: 50vw;
}

.navLinks ul {
    margin: 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-evenly;
    list-style: none;
}

.navLinks li {
    display: block;
    width: 25%;
    margin: 0 5px;
    text-align: center;
}

.navLinks li a:hover {
    border: 2px solid white;
    border-radius: 25px;
    transition: 0.2s;
    -webkit-transition: 0.2s;
}

.navLinks a {
    text-decoration: none;
    display: block;
    color: white;
    padding: 8px 0;
    letter-spacing: 4px;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.navLinks a:hover {
    text-decoration: none;
}

.navSocial {
    width: 15vw;
}

.navSocial ul {
    margin: 0;
    display: flex;
    flex-flow: row nowrap;
    list-style: none;
    float: right;
}

.navSocial li {
    height: 38px;
    width: 38px;
    border: 2px solid white;
    border-radius: 50%;
    margin-left: 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tooltiptext {
    opacity: 0;
    width: 120px;
    background-color: #303030;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: 140%;
    right: -125%;
    margin-left: -60px;
}

.tooltiptext:after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -10px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #303030 transparent;
}

.navSocial li:hover {
    background-color: white;
    transition: 0.5s;
    -webkit-transition: 0.5s;
}

.navSocial li:hover a {
    color: #191919;
}

.navSocial li:hover .tooltiptext {
    opacity: 0.8;
    transition: opacity 1s;
    -webkit-transition: opacity 1s;
}

.home {
    border: 2px solid white;
    border-radius: 25px;
}

.MoNavContainer {
    zoom: 75%;
    position: -webkit-sticky;
    top: 0;
}

.mobileNav {
    overflow: hidden;
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
}

.navBars {
    width: 30%;
    color: white;
    font-size: 40px;
    display: flex;
}

.navBars svg {
    margin: 30px;
    margin-left: 45px;
    display: block;
    cursor: pointer;
}

.navBars svg:hover {
    transition: 0.5s;
    -webkit-transition: 0.5s;
    color: #686767;
}

.MoNavLogo {
    width: 40%;
}

.MoNavLogo img {
    height: 100px;
    display: block;
    margin: 0 auto;
}

.MoNavSocial {
    width: 30%;
}

.MoNavSocial ul {
    margin: 0;
    display: flex;
    flex-flow: row nowrap;
    list-style: none;
    float: right;
    margin: 30px;
}

.MoNavSocial li {
    height: 38px;
    width: 38px;
    border: 2px solid white;
    border-radius: 50%;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.MoNavSocial li:hover {
    background-color: white;
    transition: 0.5s;
    -webkit-transition: 0.5s;
}

.MoNavSocial li:hover a {
    color: #191919;
}

.MoNavSocial a {
    color: white;
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.MoNavLinks {
    height: 0px;
    overflow: hidden;
}

.MoNavLinks ul {
    padding: 8px 60px;
    list-style-type: none;
    margin-bottom: 3px;
}

.MoNavLinks li {
    padding: 15px;
    border-bottom: 1px solid #686767;
}

.MoNavLinks li:last-child {
    border-bottom: none;
}

.MoNavLinks a {
    color: white;
    text-decoration: none;
}

.MoNavLinks a:hover {
    transition: 0.5s;
    -webkit-transition: 0.5s;
    color: #686767;
}

.mobileNavII .MoNavSocialII ul {
    margin: 0;
    display: flex;
    flex-flow: row nowrap;
    list-style: none;
}

.mobileNavII .MoNavSocialII li {
    height: 10px;
    width: 10px;
    border: 1px solid white;
    border-radius: 50%;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mobileNavII .MoNavSocialII li:hover {
    cursor: pointer;
    transition: 0.5s;
    -webkit-transition: 0.5s;
    background-color: white;
}

.mobileNavII .MoNavSocialII li:hover a {
    color: #191919;
}

.mobileNavII .MoNavSocialII a {
    color: white;
    font-size: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.link {
    color: white;
    display: flex;
    padding: 0 10px;
    cursor: pointer;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    height: 100%;
    white-space: normal;
    word-break: break-all;
}

.link:hover {
    color: white;
    text-decoration: unset;
    background-color: #616161;
}

.active_link {
    background-color: #616161;
}

.item {
    width: 100%;
    height: 800px;
    border: solid #CECED2 1px;
    border: solid #ffffff00 1px;
}

.height-45 {
    height: 45px;
}

.height-115 {
    height: 115px;
}

.height-600 {
    height: 600px;
}

.height-1000 {
    height: 1000px;
}

.height-1100 {
    height: 1100px;
}

.height-1200 {
    height: 1200px;
}

.height-1400 {
    height: 1400px;
}

.height-1800 {
    height: 1800px;
}

.nav-spacer {
    padding-top: 110px;
    background-color: #ffffff;
}

/* Media query for phones (portrait and landscape) */
@media only screen and (max-width: 1240px) {
    .item {
        width: 100%;
        height: 1100px;
        border: solid #CECED2 1px;
        border: solid #ffffff00 1px;
    }

    .profile-height {
        height: 1700px;
    }

    .skill-height {
        height: 1550px;
    }

    .work-height {
        height: 2300px;
    }

    .project-height {
        height: 1850px;
    }

    .certficates-height {
        height: 950px;
    }

    .mobileNav {
        height: 70px;
    }

    .nav-spacer {
        padding-top: 0px;
        background-color: unset;
    }

    .MoNavLogo img {
        height: 55px;
    }

    .navBars {
        font-size: 30px;
    }

    .navBars svg {
        margin: 20px;
        margin-left: 25px;
    }
}